import React from "react"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"

import sluggo from "../../../util/slug"

import styles from "./styles.module.less"

const NewsArchive = ({ items }) => {
  return (
    <section className={styles.News}>
      <Reveal>
        <header>
          <h1>News, media, events.</h1>
        </header>
      </Reveal>
      {items.map((i, j) => (
        <Item key={sluggo(i.frontmatter.title)} item={i} />
      ))}
    </section>
  )
}

export default NewsArchive

const Item = ({ item }) => {
  const {
    fields: { slug, summary },
    excerpt,
    frontmatter: { eyebrow, headline },
  } = item

  const renderExcerpt = (excerpt, summary) => {
    if (summary) {
      return (
        <div
          className={styles.excerpt}
          dangerouslySetInnerHTML={{ __html: summary }}
        />
      )
    }
    return (
      <div className={styles.excerpt}>
        <p>{excerpt}</p>
      </div>
    )
  }
  return (
    <Reveal>
      <article className={styles.Item}>
        <Link to={slug} className={styles.articleLink}>
          <div className={styles.articleIntro}>
            <span className={styles.eyebrow}>{eyebrow}</span>
            <h1>{headline}</h1>
            {renderExcerpt(excerpt, summary)}
          </div>
        </Link>
      </article>
    </Reveal>
  )
}
