import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import NewsArchive from "../components/NewsArchive"

const NewsPage = ({ data }) => {
  const items = data.news.nodes
  return (
    <Layout backgroundColor={"white"} navColor={"black"}>
      <SEO
        title="Thesis | News"
        keywords={["thesis agency", "portland agency", "marketing agency"]}
        description="Thesis began as an email marketing agency (eROI) but has evolved into a full-service digital agency—creative, marketing, strategy. All the digital things. Learn more."
      />
      <NewsArchive items={items} />
    </Layout>
  )
}

export default NewsPage

// prettier-ignore
export const pageQuery = graphql`
  query {
    news: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content\\/news/" } }
      limit: 2000
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 160)
        frontmatter {
          heroImage
          title
          description
          eyebrow
          headline
          date
          summary
        }
        html
        fields {
          slug
          summary
        }
      }
    }
  }
`
